.link {
    text-decoration: none;
  }
  .multi-repeating-linear {
    background: repeating-linear-gradient(
        190deg,
        rgba(255, 255, 255, 0.5) 40px,
        rgba(224, 255, 255, 0.5) 80px,
        rgba(192, 255, 255, 0.5) 120px,
        rgba(224, 255, 255, 0.5) 160px,
        rgba(255, 255, 255, 0.5) 200px,
        rgba(192, 255, 255, 0.5) 240px,
        rgba(224, 255, 255, 0.5) 280px,
        rgba(255, 255, 255, 0.5) 300px
      ), 
      repeating-linear-gradient(
        -190deg,
        rgba(255, 255, 255, 0.5) 30px,
        rgba(224, 255, 255, 0.5) 60px,
        rgba(192, 255, 255, 0.5) 90px,
        rgba(224, 255, 255, 0.5) 120px,
        rgba(255, 255, 255, 0.5) 150px,
        rgba(192, 255, 255, 0.5) 180px,
        rgba(224, 255, 255, 0.5) 210px,
        rgba(255, 255, 255, 0.5) 230px
      ), 
      repeating-linear-gradient(
        23deg, 
        #ADD8E6 50px, 
        #87CEEB 100px, 
        #87CEFA 150px, 
        #87CEEB 200px, 
        #1E90FF 250px, 
        #4169E1 300px, 
        #0000FF 350px, 
        #ADD8E6 370px
      ),
      repeating-linear-gradient(
        130deg, 
        #87CEEB 50px, 
        #ADD8E6 100px, 
        #87CEEB 150px, 
        #87CEEB 200px, 
        #1E90FF 250px, 
        #4169E1 300px, 
        #00008a 350px, 
        #87CEEB 370px
      ),
      repeating-linear-gradient(
        -70deg, 
        #ADD8E6 50px, 
        #87CEEB 100px, 
        #87CEEB 150px, 
        #87CEEB 200px, 
        #1E90FF 250px, 
        #4169E1 300px, 
        #e70000 350px, 
        #ADD8E6 370px
      );
}
